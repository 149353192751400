 
      .user-item{
 
        display: grid;

        grid-gap: 0px 8px;
        gap: 0px 8px;

        grid-template-columns:  48px 1fr 80px;  
        grid-template-rows: auto;
        grid-template-areas: 
          "image name btn"
          "image desc btn" 
          ; 

        padding: 16px ;
        // margin-bottom: 16px;
        border-bottom: 1px solid #eee;

        .avatar{
          cursor: pointer;
            grid-area: image; 
            border-radius: 50%;
            height: 48px;
              width: 48px;
            img{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: #eee;
            }
        }
        .name{
          cursor: pointer;
          grid-area: name;
          font-weight: bold;
          align-self: flex-end;
          font-size: 10pt;
        }
        .level{
          cursor: pointer;
          grid-area: desc;
          font-size: 9pt;
          height: 25px;
          align-self: flex-start;
        }
        .follow, .unfollow{
          grid-area: btn;
          font-size: 10pt;
          align-self: center;
          background-color: #0856bc;
          color: #fff;
          padding: 6px 12px;
          text-align: center;
          font-weight: bold;
          font-size: 10pt;
          border-radius: 40px;
          cursor: pointer;
          opacity: 0.9;
          &:hover{
            box-shadow: inset 0px 0px 1000px 1000px #00000022;
            opacity: 1;
          }
        }
        .unfollow{
          background-color: #fff;
          border: 1px solid #ddd;
          color: #444;
        }
      } 