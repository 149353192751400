.suggested-profiles{
 

    .user-list{
      .user-list-title{
        font-weight: bold;
        font-size: 18pt;
        font-weight: bold;
        padding: 14px 16px;
        border-bottom: 1px solid #eee;
        margin-bottom: 8px;
        
      } 

    }

}