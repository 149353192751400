.comment-list {
  // margin-top: 8px;
  background-color: #fff;
  padding: 8px;
}
// .comment-list .comment-item{
//   border-top: 2px solid #f2f2f2;

//   width: 100%;
//   margin: 4px 0px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   padding: 16px 16px;
//   background-color: #fff; 
//   line-height: 1.6;

//   max-width: 768px;

//   .comment-item-sender{
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     font-size: 9pt;
//     margin-top: 8px;
//     padding-top: 8px;
//     border-top: 1px solid #eee;
//     width: 100%;
//     color:#999;
//     opacity: 0.6;

//     img{
//       width: 24px;
//       height: 24px;
//       border:1px solid #ddd;
//       border-radius: 50%;
//       margin-right: 8px;

//     }
//     .name{
//       padding-right: 16px;
//     }
//     .time{
//       padding-left: 16px;
//     }
//   }

//   .comment-item-title{
//     font-weight: bold;
//     padding-bottom: 4px; 
//   }
//   .comment-item-description{
//     padding: 8px 0px;
//   }
//   .comment-item-content{
//     padding-top: 16px; 
//     width: 100%; 
//     img{
//       width: 100%;
//     }
//     video{
//       width: 100%;
//     }

//   }
   

// }



// @media (max-width: 768px)  { 
//  .post-item .post-item-sender .label{
//      display: none;
//   }
// }


// @media (max-width: 768px)  { 
//   .comment-list .comment-item .comment-item-sender .label{
//      display: none;
//   }
// }