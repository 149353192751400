

.static-page{
    padding: 0px;
    margin: 0px; 

      .head-wrapper{
        background-color: #fff;
      }
      .head{
        width:1100px;
        margin:0px auto;
        padding: 30px 0px;
        .title{
            text-align: left;
            font-size: 26pt; 
        }
        .desc { 
          font-size: 12pt;
          text-align: left;
          padding: 10px 0px;
        }
      }
    .page{ 
        margin:0px auto;
        padding-top:10px; 
        .page-subdesc { 
          text-align: left;
          color:#666;
        }
        .page-sub-title{
          font-size: 18pt;
          text-align: left;
          padding: 20px 0px;
        }
        .page-text{
          text-align: left;
          padding: 0px 0px;
          padding-bottom: 30px;
          p{
            margin:20px 0px;
          }
          h2{ 
             font-size: 16pt;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 22px;
          }
          h5{ 
            font-size: 16pt;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 22px;
          }
        }
        .price-boxes{ 
          flex-wrap: wrap;
          .box{
            min-width: 200px;
            .box-content{
              padding: 0px;
            }
          }
        }
        .boxes { 
          display: flex;
          flex-direction: row;
          padding-top: 20px;
          flex-wrap: wrap;
        
        }
          .box{
            background-color: #fff;
            padding:10px;
            flex:1;
            margin:10px;
            font-size:10pt;
            display: flex;
            .box-content{
              flex:3;
              justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: column;
              padding:10px;
              * {
                width: 100%;
              }
            }
            .box-image{ 
              flex:1;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              img{
                width:100%;
              }
            }
            .box-title{
              font-weight: bold;
              border-bottom: 1px solid #eee;
              padding-bottom: 10px;
            }
            .price-box-price{
                padding: 10px 10px;
                text-align: left;
                .value{
                    font-weight: bold;
                    font-size: 26pt;
                    color:#666;
                }
                .time{
                  color:#666; 
                }
                .desc{
                  display: block;
                  color:#666; 
                  font-size: 9pt;
                  padding: 5px 0px;
                }
            }

            .buy-button{
              margin:10px;
            }
            .box-line{
                color:#666;
                padding: 10px;
                text-align: left;
              
            }
            .sub-title{
              color:#666;
              padding: 10px;
              font-weight: bold;
              text-align: left;
              margin-top: 10px;
            }
            .price-box-spec{
              color:#666;
              padding: 10px; 
              text-align: left;
              font-size: 10pt;
            }
          }
          .box:last-child{  
          }

          
    }
    .resources-page{
      .box{
        min-width: 300px;
        flex-direction: column;
      }
    }
}




/* Plain CSS */
@media (max-width: 1120px)  { 
  .static-page {
    
    .head{ 
        
        width: 100%;  
        padding: 10px; 
    } 
  }
}



@media (max-width: 900px)  { 
  .static-page{ 
    .head{ 
        width: 100%; 
        padding:20px;
        padding-top: 80px;
        padding-bottom: 40px;
        .desc { 
          color:#666;
          padding-top: 20px;
        }
 
    } 
    .page{

      .page-subdesc { 
        padding: 10px;
      } 
          .box{ 
            margin:10px;
            margin-bottom: 20px;
            flex-direction: column;
          }
          .box:last-child{ 
            margin-right:10px;
          }
      
    }
  }
}


.not-found-page{
    .head-wrapper{ 
      .head{ 
        .title, .desc{
          width: 100%;
          text-align: center;
        }
      }
    }
    .page{
      background-color: #fff;
      width:100%;
      padding: 0px 30px;

      img{
        min-width: 250px;
        max-width: 600px;
        width: 100%;
        margin-bottom: 40px;
        
      }
    }
}