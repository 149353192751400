 



@keyframes fadewindowfornew {
  0% {  opacity: 0;} 
  50% { opacity: 1; 
    background-color: #ff000010;  } 
  100% { opacity: 1;} 
}
@keyframes fadewindow {
  0% {  opacity: 0;}  
  100% { opacity: 1;} 
}

@keyframes fadevideo {
  0% {  opacity: 0;   }  
  5% { opacity: 0;  } 
  9% { opacity: 1;  background-color: #ddd; } 
  10% { opacity: 1;  background-color: #ddd;  } 
  90% { opacity: 1;   background-color: #ddd;  } 
  100% { opacity: 1;     } 
}

 
.post-list-item{

  width: 100%;
  // margin: 48px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding-bottom: 24px;
  padding-top: 24px;
  // border-radius:  16px;
  line-height: 1.6;

  // max-width: 600px;
  position: relative;
  
  animation: fadewindow 500ms forwards;
  animation-iteration-count: 1;
  transition: 200ms;
  &:hover{
    background-color: #fafafa;
  }
  &.--is-new { 
    animation: fadewindowfornew 800ms forwards;
    animation-iteration-count: 1;

  }
  &:first-child{
    margin-top: 8px;
  }

  .post-item-sender{
    width: 100%;
    display: grid;
    
    grid-gap: 0px 12px;
    gap: 0px 12px;

    grid-template-columns:  48px 1fr;  
    grid-template-rows: auto;
    grid-template-areas: 
      "image name"
      "image time" 
      ; 


    
    font-size: 11pt;
    margin-bottom: 8px;
    padding-bottom: 8px;
    // border-bottom: 1px solid #f2f2f2;
    color:#444;
    opacity: 0.9;
    cursor: pointer;

    transition: 300ms;

    &:hover{
      opacity: 1;
      color:#222;
      img{ 
        box-shadow: 0px 0px 2px 4px #00000015;
      }
    }
    img{
      width: 48px;
      height: 48px;
      border:1px solid #ddd;
      border-radius: 50%; 
      grid-area: image;
      transition: 500ms;
    }
    .label{

    }
    .name{ 
      font-weight: bold; 
      font-size: 10pt;
      grid-area: name;
      align-self: flex-end;
      padding-top: 4px;
    }
    .time{ 
      grid-area: time;
      font-size: 9pt;
      align-self: flex-start;
      color:#999;
    }
  }

  
  .post-item-title{
    font-weight: bold;
    padding-bottom: 4px;
    font-size: 14pt;
    cursor: pointer;
    word-break:normal;
  }
  .post-item-description{
    padding-bottom: 16px;
    font-size: 10pt;
    cursor: pointer;
    word-break:normal;
    overflow: hidden;
  }
  .post-item-content{
    width: 100%;
    position: relative;
    cursor: pointer;
    padding-left: 58px;
    display: flex;
    flex-direction: column;
    img{
      width: 100%;
      border-radius: 16px;
      max-height: 200px;
      max-width: 300px;
      background-color: #ddd;
      border: 2px solid #eee;
    }
    video{
      width: 100%;
      border-radius: 16px;
      max-height: 200px;
      max-width: 300px;
      background-color: #ddd;
      border: 2px solid #eee;
    }

  }

  .post-item-nsfw{
    display: none;
  }
  .post-item-spoiler{
    display: none;
  }
  .post-item-nsfw-spoiler{
    display: none;
  }
  &.nsfw:not(.visible):not(.spoiler) .post-item-nsfw{
    display: block;
  }
  &.spoiler:not(.visible):not(.nsfw) .post-item-spoiler{
    display: block;
  }
  &.spoiler.nsfw:not(.visible) .post-item-nsfw-spoiler{
    display: block;
  }
  &.nsfw, &.spoiler{
    .post-item-nsfw, .post-item-spoiler, .post-item-nsfw-spoiler{
     
      position: absolute;
      color:#fff;
      left:50%;
      top:50%;
      transform: translate(-50%, -50%);
      background-color: #00000099;
      border-radius: 50px;
      padding: 8px  12px;
      font-size: 14pt;
      opacity: 0.6;
      z-index: 1;
      cursor: pointer;
      width: 320px;
      text-align: center;
      &:hover{
        opacity: 0.9;
      }
    } 
  }
  &.nsfw:not(.visible){
    .post-item-content-typed, .post-item-description{
      
      filter:blur(10px);
      opacity: 0.4;
    }
    .post-item-content{
      img, video{
        filter:blur(10px);
        opacity: 0.4;
      }
    }
  }  

  &.spoiler:not(.visible){
    .post-item-content-typed, .post-item-description{
      
      filter:blur(10px);
      opacity: 0.4;
    }
    .post-item-content{
      img, video{
        filter:blur(10px);
        opacity: 0.4;
      }
    }
  }  

  .post-item-content-typed{
    position: relative;
    align-self: flex-start;
    justify-self: center;
    
  }
  
  .post-item-bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px;
    padding-left: 58px;
      .post-item-comment,
      .post-item-like{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 16px;
        cursor: pointer;
        padding: 6px 16px;
        filter: grayscale(1);
        opacity: 0.4;
        font-size: 10pt;
        font-weight: bold;
        &:first-child{
          padding-left: 6px;
        }
        &:hover{
          // box-shadow: inset 0px 0px 1000px 10000px #00000011;
          opacity: 0.8;
        }
        img{
          width:16px;
          margin-right: 8px;
        }
        .font-icon{
          margin-right: 8px;
          font-size: 12pt;
          font-weight: bold;
        }
        &.liked{
          color:#135ec7;
          filter: none;
          opacity: 0.8;
          .font-icon{ 
          }
        }
      }
  }


  .play-button{
    background: #00000077;
    color:#fff;
    font-size: 40pt;
    position: absolute;
    top:50%;
    left:50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
   

  .youtube-container {
    width: 50%;
    position: relative;
    padding-bottom: 32.25%; /* 16:9 */
    height: 0;
  
    animation: fadevideo 10000ms forwards;
    animation-iteration-count: 1;
    border-radius: 16px;
    overflow: hidden;

  }
  .youtube-container iframe {
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}


.post-item-comments{
  flex:1;
  width: 100%;
  padding-left: 58px;
  .post-item-comment-more{
    width: 100%; 
    padding-bottom: 8px;
    text-align: center;
    font-size: 14pt;
    opacity: 0.4;
    line-height: 1;
  }
}

 



/* Plain CSS */
@media (max-width: 768px)  { 


.post-item-comments{ 
  padding-left: 48px;
}
  .post-list-item{
    
    padding: 12px;
    line-height: 1.4;
    padding-bottom: 16px;
    padding-top: 16px;
    .post-item-sender{
       
    grid-gap: 0px 8px;
    gap: 0px 8px;
      grid-template-columns:  40px 1fr;  
      margin-bottom: 4px;
      padding-bottom: 4px;
      img{
        width: 40px;
        height: 40px;
      }
    }
 


    .post-item-title{ 
      font-size: 12pt; 
    }
    
    .post-item-description{
      color:#666;
    }
    .post-item-content{
      padding-left: 48px;
    }


    .post-item-bottom{ 
      padding-left: 48px;
    }
  }

}