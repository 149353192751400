.page.messenger{

  height: 100vh;
  padding-bottom: 0px;

  .messenger-content{
    display: flex;
    flex-direction: column;
    height: 100%; 
  }

  .message-box{
    min-height: 80px;
    display: flex;
    flex-direction: row;
    border-radius: 0px;
    overflow: hidden;
    border-top:1px solid #eee;
    
    textarea{
      margin: 8px 0px;
      border:0px;
      padding: 16px 24px;
      background-color: transparent;
    }
    .send-button{
      background-color: #ffe311;
      padding: 16px 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      &:hover{
        box-shadow: inset 0px 0px 100px 100px #00000011;
      }
    }
  }
}

.messenger-content-placeholder{
  font-size: 120%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  text-align: center;
  color: #888;
  .font-icon{
    font-size:32pt;
    color:#ffc600;
  }
}



/* Plain CSS */
@media (max-width: 768px)  { 


  
  .page.messenger .message-box{
    min-height: 48px;
     
    textarea{
      height: 40px;
      min-height: 20px;
      padding: 10px 16px;
      margin: 4px 0px;
    }
    .send-button{
      padding: 12px 20px;
      &:hover{
        
      }
    }
  }

}