
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg);  }
}

.login_box.wrong{
  animation: shake 0.3s;
  animation-iteration-count: 1;
  background-color: #ff000011;
  input[type="text"],  input[type="password"]{
    
  }
}

.login_box { 
  position: relative;
  transition: 500ms;
  background-color: #fff;
  border-radius: 3px; 
  min-width:300px;
  font-size:10pt;
  padding: 16px;
  color:#000;
  .wrong-message{
    
    opacity: 0;
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    padding: 5px;
    background-color: #ff000022;
  }

  .wrong-message.show{ 
    opacity: 1; 
  }


  &.loading{
     
      opacity: 0.2;
      user-select: none;
      pointer-events: none;
      z-index: 0;
     
    
  }
}


.popup .loader{
  position:absolute;
}

.login_box .login_box_title{ 
  font-weight: bold;
  font-size: 14pt;
  margin-top: 16px;
  text-align: left;
}
.login_box .login_box_label{ 
  font-weight: bold;
  margin-top: 16px;
  text-align: left;
}
.login_box input{ 
  width: 100%;
}

.login_box .login_box_button{
  margin-top: 32px;
}

.login_box .login_box_links{
  display: flex;
  padding: 16px;
}
.login_box .login_box_links a{
  flex:1;
  text-align: center;
}