.empty-list{
  font-size: 150%;
  padding: 16px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .empty-list-text{
    padding: 16px;
    text-align: center;
    opacity: 0.5;
  }
  img{ 
    width: 98%; 
    margin: 0px 1%;
    height: auto;
    border-radius: 70px;
    filter: grayscale(1);
  }
  .image{
    overflow: hidden; 
    width: 90%;
    height: auto;
    position: relative;
    border-radius: 70px;

    &:after{
      border-radius: 70px;
      content:  ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      z-index: 10;
      top:0px;
      box-shadow: inset 0px 0px 25px 40px #ffffff;
    }
  }
}