.session-profiles{
 

    .user-list{
     
      height: 100vh;
      border-right: 1px solid #eee;
      display: flex;
      flex-direction: column;
      .user-list-title{
        font-weight: bold;
        font-size: 18pt;
        font-weight: bold;
       
        padding: 14px 16px;
        border-bottom: 1px solid #eee;
        margin-bottom: 8px;

        position: relative;
        
        .start-search{
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          padding: 8px;
          cursor: pointer;
        }

           
 
        
      } 
      .user-list-scroller{
        overflow: auto;
        padding-bottom: 120px;
      }
      .search-user{
        // padding: 8px;
        border-bottom: 1px solid #eee;
        padding: 0px;
        padding-bottom: 4px;
        input{
          margin: 0px;
           padding: 8px 12px;
          background-color: transparent;
          border: 0px none;
        }
      }
    }

}