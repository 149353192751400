.logo{
  width:140px;
  height: 35px;
  position: relative;
  zoom: 1;
}


.logomask{
  position:absolute;
  width:142px;
  z-index:10;
  top:-1px;
  left:-1px;
  height: 36px;
  filter: contrast(10) drop-shadow(1px 1px 2px  #00000088);
}
.logomask.resdf{
  opacity: 0.1;
  display: none;
}

.logobg{
  position:absolute;
  width:139px;
  height:34px;
  overflow: hidden;

  

  img:not(.logomask){
    width: 100%;
    
    animation: animate-logo 10000ms linear;
    animation-iteration-count: 100;
     
  }
  
}


@keyframes animate-logo {
  0% {  transform: translateY(-90%);}  
  100% {  transform: translateY(-0%); } 
  // 100% {  transform: translateY(-90%); } 
}



/* Plain CSS */
@media (max-width: 768px)  { 
  .logo{ 
    zoom: 0.6;
    width:140px; 
  }
  
}
