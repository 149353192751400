// .page.post{
//   //  padding-top: 10px;  
//   //  display: flex;
//   //  flex-direction: row; 
//   //  justify-content: flex-start;
//   // text-align: left;

//   // .post-head-list{ 
//   //   margin-left: 16px;
//   //   margin-top: 2px; 
//   //   min-width: 200px;
//   //   max-width: 300px;
//   // }

//   .main-title{
//     // display: none;
//   }

//   .back-button{
//     cursor: pointer;
//     font-size: 12pt;
//     padding-top: 16px;
//     color: #999;
//   }

//   .post-item-empty{
//      min-height: 300px;
//      width: 100%;
//   }

//   .post-item{
//     // flex:1;
//     width: 100%; 
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: flex-start;
//     padding: 16px;
//     background-color: #fff;
//     border-radius:  4px;
//     line-height: 1.6;
    
//    cursor: default;
  
//     // .post-item-sender{
//     //   display: flex;
//     //   width: 100%;
//     //   flex-direction: row;
//     //   align-items: center;
//     //   font-size: 10pt;
//     //   margin-bottom: 16px;
//     //   padding-bottom: 16px;
//     //   border-bottom: 1px solid #eee;
//     //   color:#888;
//     //   img{
//     //     width: 24px;
//     //     height: 24px;
//     //     border:1px solid #ddd;
//     //     border-radius: 50%;
//     //     margin-right: 8px;
  
//     //   }
//     //   .name{
//     //     padding-right: 16px;
//     //   }
//     //   .time{
//     //     padding-left: 16px;
//     //     font-size: 9pt;
//     //   }
//     // }


//   .post-item-sender{
//     width: 100%;
//     display: grid;
    
//     grid-gap: 0px 8px;
//     gap: 0px 8px;

//     grid-template-columns:  48px 1fr;  
//     grid-template-rows: auto;
//     grid-template-areas: 
//       "image name"
//       "image time" 
//       ; 


    
//     font-size: 11pt;
//     margin-bottom: 16px;
//     padding-bottom: 16px;
//     border-bottom: 1px solid #f2f2f2;
//     color:#333;
//     opacity: 1;
//     cursor: pointer;
//     img{
//       width: 48px;
//       height: 48px;
//       border:1px solid #ddd;
//       border-radius: 50%;
//       margin-right: 8px; 
//       grid-area: image;
//     }
//     .label{

//     }
//     .name{ 
//       font-weight: bold; 
//       grid-area: name;
//       align-self: flex-end;
//     }
//     .time{ 
//       grid-area: time;
//       font-size: 10pt;
//       align-self: flex-start;
//     }
//   }


  
//     .post-item-title{
//       cursor: default;
//       font-weight: bold;
//       padding-bottom: 4px;
//       font-size: 16pt; 
//     }
//     .post-item-description{
//       cursor: default;
//       padding-bottom: 16px;
//       font-size: 12pt; 
//     }
//     .post-item-content{  
//       cursor: default;
//       width: 100%;
//       img{
//          width: 100%;
//          height: auto;
//          max-height: 600px;
//          border-radius: 16px;
//       }
//       video{
//         width: 100%;
//         border-radius: 16px;
//       }
  
//     }
    
//     .post-item-bottom{
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       padding-top: 8px;
      
//         .post-item-comment,
//         .post-item-like{
//           display: flex;
//           flex-direction: row;
//           align-items: center;
//           margin-right: 16px;
//           cursor: pointer;
//           padding: 16px 16px;
//           filter: grayscale(1);
//           opacity: 0.4;
//           font-size: 9pt;
//           font-weight: bold;
  
//           &:first-child{
//             padding-left: 6px;
//           }
//           &:hover{
//             opacity: 0.8;
//             // box-shadow: inset 0px 0px 1000px 10000px #00000011;
//             filter: grayscale(0);
//           }
//           .font-icon{
//             margin-right: 8px;
//             font-size: 14pt;
//           }
//           img{
//             width:16px;
//             margin-right: 8px;
//           }

//           &.liked{
//             color:#135ec7;
//             filter: none;
//             opacity: 0.8;
//             .font-icon{ 
//             }
//           }
//         }


//     }



//     .youtube-container {
//       position: relative;
//       padding-bottom: 56.25%; /* 16:9 */
//       height: 0;
//     }
//     .youtube-container iframe {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//     }
  
//   }

// }



// .youtube-container {
//   position: relative;
//   padding-bottom: 35%; /* 16:9 */
//   height: 0;
// }
// .youtube-container iframe {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 350px;
//   height: 200px;
// }



// iframe{
//   width: 800px;
//   height: 500px;
// }


// @media (max-width: 1400px)  { 

//   iframe{
//     width: 670px;
//     height: 500px;
//   }
// }

// @media (max-width: 1000px)  { 

//   iframe{
//     width: 670px;
//     height: 500px;
//   }
// }

.post .main-body{
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 16px;
  flex: 1;

  >.post-item, >.comment-list {
    background-color: transparent;
  }
  >.post-item, >.comment-list .comment-item{
    background-color: transparent;
  }
}
 


@media (max-width: 768px)  { 
  .page.post{
    // padding: 10px 0px;
  }
  .post .post-head-list{
    display: none;
  }
}




.post-placeholder{
  flex: 1;
}


.page.channel{
  grid-template-columns: 1fr;
  grid-template-rows:  auto;
  grid-template-areas: 
      "page";

  background-color: #000;
  box-shadow: 0px 0px 1000px 1000px #000;
  
  .main-title{
    display: none;
  }
  .main-center{
    border:0px none;
  }
}
 
  .swiper-wrapper{  
    width: 100%;
    height: 100%;
}
swiper-container ,  .swiper{
  width: 100%;
  height: 100%;
}

swiper-slide , .swiper-slide{
  text-align: center;
  font-size: 18px;
  background: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  
}

swiper-slide img,  .swiper-slide img {
  display: block;
  width: 100%; 
  object-fit: cover;
}
swiper-slide iframe {
  display: block;
  width: 100%; 
  height: 100%;
  object-fit: cover;
  user-select: none;
  // pointer-events: none;
}
swiper-slide video,  .swiper-slide video {
  display: block;
  width: 100%;  
  max-width: 100%;
  object-fit: cover;
  user-select: none;
  // pointer-events: none;
}

.swiper-title{
  position: absolute;
  top:auto;
  color:#fff;
  padding: 8px;
  font-size: 10pt;
  bottom:0px;
  left:0px;
  width: 100%;
  text-align: left;
  background-color: #00000011;
}


.page.channel + .mobile-menu {
  background-color: #000;
  color:#fff;
 
  .mobile-menu-item {
    opacity: 0.5;
  }

  .mobile-menu-item.selected {
    background-color: #00000055;
    opacity: 0.9;
  }
}


.page.channel{
  .swiper-actions{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0px;
    bottom: 120px;
    color:#fff; 
    font-weight: bold;
    padding: 8px;
  .swiper-action{  
    background-color: #00000099;
    border-radius: 50%;
    height: 36px;
    width: 36px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 8px 0px;
  }

  }
}