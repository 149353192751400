.profile-top{

  padding: 16px;
  background-color: #fafafa;

  display: grid;
  grid-gap: 0px 16px;
  gap:0px 16px;
   
  grid-template-columns: 120px 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
  "avatar name"
  "avatar stats"
  "avatar stats" ;

  margin-bottom: 16px;


  .avatar{
    grid-area: avatar;
     
      img{
        min-height: 120px;
        background-color: #ddd;
        width:100%;
        height: auto;
        border-radius: 50%;
      }
  }
  .name{
    padding: 8px 16px;
    padding-top: 16px;
    grid-area: name;
    
    text-align: left; 
    font-weight: bold;
  }
  .stats{
    grid-area: stats;
    position: relative;
    
    .stats-line{
      padding: 4px 16px;
      font-size: 10pt;
      // border-bottom: 1px solid #f2f2f2;
    }
  }



  .follow, .unfollow{
    position: absolute; 
    right: 0px;
    top:8px;
    font-size: 10pt;
    align-self: center;
    background-color: #0856bc;
    color: #fff;
    padding: 6px 12px;
    text-align: center;
    font-weight: bold;
    font-size: 10pt;
    border-radius: 40px;
    cursor: pointer;
    opacity: 0.9;
    width: 100px;
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000022;
      opacity: 1;
    }
  }
  .unfollow{
    background-color: #fff;
    border: 1px solid #ddd;
    color: #444;
  }

}

// .page.profile{
//    padding-top: 10px;  
//    display: flex;
//    flex-direction: row; 
//    justify-content: flex-start;
//   text-align: left;

//   .profile-left{
//     padding: 16px;
//     width:220px
//   }
//   .profile-right{
//     padding: 16px;
//     width:300px;
//     padding-right: 0px;
//   }
//   .profile-center{
//     flex:1;
//     border-right: 1px solid #f2f2f2;
//     border-left: 1px solid #f2f2f2;
//   }
 
// }

// .profile-left{
//   .avatar{
//     width:100%;
//     img{
//       width:100%;
//       height: auto;
//       border-radius: 50%;
//     }
//   }
//   .name{
//     text-align: center;
//     padding: 16px;
//     font-weight: bold;
//   }
//   .stats{
//     .stats-line{
//       padding: 16px 0px;
//       font-size: 10pt;
//       border-bottom: 1px solid #f2f2f2;
//     }
//   }
// }


// .user-list{
//   .user-list-title{
//     font-weight: bold;
//     padding: 16px 0px;
//     border-bottom: 1px solid #eee;
//     margin-bottom: 8px;
    
//   }
//   .user-item{
//     display: grid;

//     grid-gap: 0px 8px;
//     gap: 0px 8px;

//     grid-template-columns:  48px 1fr 80px;  
//     grid-template-rows: auto;
//     grid-template-areas: 
//       "image name btn"
//       "image desc btn" 
//       ; 

//     padding: 8px 0px;
//     margin-bottom: 8px;
//     border-bottom: 1px solid #eee;
//     .avatar{
//         grid-area: image;
//         img{
//             width: 48px;
//             height: 48px;
//             border-radius: 50%;
//         }
//     }
//     .name{
//       grid-area: name;
//       font-weight: bold;
//       align-self: flex-end;
//     }
//     .level{
//       grid-area: desc;
//       font-size: 10pt;
//       align-self: flex-start;
//     }
//     .follow{
//       grid-area: btn;
//       font-size: 10pt;
//       align-self: center;
//       background-color: #0856bc;
//       color: #fff;
//       padding: 6px 12px;
//       text-align: center;
//       font-weight: bold;
//       font-size: 10pt;
//       border-radius: 40px;
//       cursor: pointer;
//       opacity: 0.9;
//       &:hover{
//         box-shadow: inset 0px 0px 1000px 1000px #00000022;
//         opacity: 1;
//       }
//     }
//   }
// }


// @media (max-width: 768px)  {  

// }


 