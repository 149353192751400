
.home-more{

    animation: rotate-more 2000ms linear;
    animation-iteration-count: infinite; 
  
}


@keyframes rotate-more {
    0% {  transform: rotate(0deg); }  
    100% {  transform: rotate(359deg); }  
  }
  
  