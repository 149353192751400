.mobile-menu{
  display: flex;
  flex-direction: row;
  align-items: center;

  &.disabled{
    pointer-events: none;
    opacity: 0.2;
  }

  .mobile-menu-item{
    flex:1;
    font-size: 9pt;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    padding: 8px 0px;

    &.selected{
      background-color: #ffe311;
    }

    .font-icon{

    }
    .mobile-menu-item-text{
      font-size: 7pt;
      a{
        text-decoration: none;
        color: #000;
      }
    }
    .mobile-menu-item-popover{
      .left-menu-item{
        
      }
    }
  }

}