.file-upload{
  margin: 0px -16px;
}

.filepond--drop-label{
  background-color: #fff;
}

.filepond--panel-center.filepond--panel-root{
  background: #fff;
}

.filepond--panel-bottom.filepond--panel-root{
  background: #fff;
}

.filepond--image-preview-wrapper{
  border-radius: 16px;
}

.filepond--panel-top.filepond--item-panel{
  border-radius: 16px;
  height: 16px;
}
.filepond--panel-bottom.filepond--item-panel{
  border-radius: 16px;
  height: 16px;
}

.filepond--root :not(text){
  border-radius: 16px;
}

.filepond--image-canvas-wrapper{
  zoom:0.92;
  margin-top: 4%;
  margin-left: 4%;
}