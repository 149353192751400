.app {
  text-align: center;
  background-color: white;
  height: 100%; 
  .page{ 
      width: 1150px;
      margin: 0px auto;
      
      padding-bottom: 100px;
      .page-title{
        font-size:20pt;
        margin: 30px 0px;
      }
  }
}


// @media (max-width: 768px)  { 
//         .app {
          
//           .page{ 
//               width: 100%; 
//               padding:10px;
//               padding-top: 70px;

//               .page-title{
//                 font-size:14pt;
//                 text-align: left;
//                 margin-top:0px;
//                 margin-bottom:20px;
//               }
//           }
//           .app-header {
//             width: 100%;  
//             position: fixed;
//             z-index: 100;
//             box-shadow: 1px 1px 10px 10px #00000011;
//             .app-logo {
//               width: 100px; 
//               margin:16px 0px; 
//               margin-left: 16px;
               
//             }
//             .menu { 
//               display: none;
//               position: fixed;
//               bottom:0px;
//               background-color: #fff;
//               width: 100%;
//               left:0px;
//               box-shadow: 1px 1px 20px 10px #00000022;
//               height: 50px;
//               justify-content: center;
//               align-items: center;
//               padding:0px;
//               z-index: 100;
//               .menu-item{
//                 flex:1;
//                 height: 100%;
//                 justify-content: center;
//                 align-items: center;
//                 display: flex;
//               }
//               .menu-item.selected{ 
//                 background-color: #00CFE4;
//                 color: #000;
//               }
//             }
//           }
//         }
// }




// .app-logo { 
// }
 
// .app-header-wrapper {
//     background-color: #fff;
//     border-bottom: 1px solid #eee;
//     .app-header {
//       width: 1150px;  
//       margin:0px auto;
//       font-size: 12pt;
//       color: #000;
//       position: relative;
//     }
// }
 

// .app-header { 
//   display: flex; 
//   flex-direction: row; 
//   font-weight: bold;
  
//   align-items: center;
//   justify-content: center;
//   user-select: none;

//   .app-logo{
//     width:140px;
//     cursor: pointer;
//     margin:16px 16px;
//   } 

//   .menu, .account{ 
//     display: flex;
//     .menu-item{
//       padding: 10px;
//       cursor: pointer;
//       text-decoration: none;
//     }

//     .menu-item:hover{
//       color: #555;
//     }
//     .menu-item.selected{ 
//       color: #00CFE4;
//       cursor: default;
//     }
//   } 
//   .search{

//     flex:1;
//     padding-left: 8px;
//     padding-right: 8px;

//     input{
//       border:1px solid #eee;
//       padding: 8px 16px;
//       font-size: 10pt;
//       border-radius: 100px;
//     } 
//   }

//   .language{ 
//     padding-right: 16px;
//     select{
//       cursor: pointer;
//       border:1px solid #eee;
//       padding: 8px 8px;
//       font-size: 10pt;
//       border-radius: 100px;
//     } 
//   }
//   .menu{
//     flex:1;
//     padding-left: 20px;
//   }
// }






.page {
  padding-top: 0px;
  text-align: left;
  display: flex;
  flex-direction: row;


  display: grid;
  grid-gap: 0px 0px;
  gap:0px 0px;
   
  grid-template-columns: 200px 1fr 300px;
  grid-template-rows: auto 1fr 1fr 1fr;
  grid-template-areas: 
  "left title right"
  "left center right"
  "left center right"
  "left center right";



  .main-center{
    grid-area: center; 
    align-content: flex-start;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee; 
   
  }
  .main-tabs{
    display: none;
  }

  .main-left{ 
    grid-area: left;  
  }

  .main-right{
    grid-area: right;  
     
  } 

  .main-title{
    grid-area: title; 
    padding: 12px 16px;
    font-size: 20pt;
    font-weight: bold;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom:1px solid #eee;

    animation: fade-page-title 1200ms forwards;
    animation-iteration-count: 1; 

    .logo{
      display: none;
    }
    .main-title-button{ 
      display: none;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 4px;
      opacity: 0.6;
      
      .font-icon{
        font-weight: bold;
      }
    }
    .main-title-switch{
      display: none;
      border: 0px none;
      background-color: transparent;
      padding: 0px;
      font-size: 12pt;
      font-weight: bold;
      border-radius: 0px;
      opacity: 0.6;
    }
    .main-title-text{ 
    }
    .main-title-text-mobile{
      display: none;
    }



  }

}


@keyframes fade-page-title {
  0% {  opacity: 0;}  
  50% {  opacity: 0;}  
  100% { opacity: 1;} 
}






/* Plain CSS */
@media (max-width: 1400px)  { 
  .app {
    
    .page{ 
        width: 1020px;   
 
    }
    .app-header-wrapper .app-header {
      width: 1020px;   
    }
  }
}

/* Plain CSS */
@media (max-width: 1020px)  { 
  .app {
    
    .page{ 
        width: 100%;  
        padding: 10px;
 
    }
    .app-header-wrapper .app-header {
      width: 100%;
    }
  }
}

.font-icon{
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48
}
 
.font-icon.filled.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48
} 





.account{
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #3ca6e4;
  .login-button{
    padding: 8px 16px;
    margin: 0px 16px;
    cursor: pointer;
    background-color: #ffc600;
    border-radius: 200px;
  }
  .register-button{ 
    padding: 8px 16px;
    margin: 0px 16px;
    cursor: pointer;
    background-color: #ffc600;
    border-radius: 200px;
  }

}








/* Plain CSS */
@media (max-width: 768px)  { 
  
  body{
    overflow: hidden;
    height: 100%;
  }



.account{ 
  position: absolute;
  left:0px;
  bottom:0px;
  z-index: 100;
  padding: 8px 0px;
  width: 100%;
  font-size: 90%;
  .login-button,  .register-button{
    margin: 5px;
  }
}



  .app {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    .page{
      flex: 1;
      height: calc(100% - 100px);
      .main-center{ 
        overflow: auto;
        height: 100%;
      }
    }
    .mobile-menu{
      height: 50px; 
      background-color: #fff;
      border-top:2px solid #0000000a;
    }
  }

.app .page {
  padding: 0px;
  margin: 0px;
  grid-gap: 0px;
  gap:0px;
  grid-template-columns:  1fr ;
  grid-template-rows: 50px auto;
  grid-template-areas: 
  "title"
  "page";



  .main-title{
    grid-area: title; 
    font-size: 12pt;
    display: flex;
    flex-direction: row;
    align-items: center;

    animation: fade-page-title 400ms forwards;
    animation-iteration-count: 1; 

    .logo{
      display: block;
      margin-right: 20px;
    }
    .back-button{
      padding-top:0px;
    }
    .main-title-switch{
      display: block; 
      margin-left: 12px;
      margin-right: 12px;
      // max-width: 165px;
      flex:1;
      // text-align: center;
    }
    .main-title-text{ 
      display: none;
    }
    .main-title-text-mobile{
      display: flex;
      flex:1;
      // padding-left: 12px;
      align-items: center;
      font-size: 12pt;

      .main-title-back-button{ 
        display: flex; 
        align-items: center;
      }
    }
    .main-title-button{ 
      display: flex;
    }

  }
  .main-center{
    grid-area: page;  

  }


  .main-tabs{
      display: flex;
       
      position: sticky;
      top:0px;
      z-index: 10;
      background-color: #fff;
      .main-tab{
          padding: 8px 16px;
          &.selected{
            color:#d23505;
          } 
          &:first-child{
            padding-left: 0px;
          }
      }
  }

  .main-left{  
    display: none;
  }

  .main-right{
     display: none;
     
  } 


}


    .app .page.messenger{
      height: calc(100% - 100px);
    }

    .session-profiles{
      .user-list-title{
        display:none;
      } 
    }
}