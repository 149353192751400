.me-top{

  padding: 16px;
  // background-color: #fafafa;

  display: grid;
  grid-gap: 0px 8px;
  gap:0px 8px;
   
  grid-template-columns: 100px 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
  "avatar name"
  "avatar stats"
  "avatar stats" ;

  margin-bottom: 8px;


  .avatar{
    grid-area: avatar;
     
      img{
        width:100%;
        height: auto;
        border-radius: 50%;
      }
  }
  .name{
    padding: 8px ; 
    grid-area: name;
    
    text-align: left; 
    font-weight: bold;
  }
  .stats{
    grid-area: stats;
    position: relative;
    
    .stats-line{
      padding:   4px 8px;
      font-size: 10pt;
      // border-bottom: 1px solid #f2f2f2;
    }
  }



  .follow, .unfollow{
    position: absolute; 
    right: 0px;
    top:8px;
    font-size: 10pt;
    align-self: center;
    background-color: #0856bc;
    color: #fff;
    padding: 6px 12px;
    text-align: center;
    font-weight: bold;
    font-size: 10pt;
    border-radius: 40px;
    cursor: pointer;
    opacity: 0.9;
    width: 100px;
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000022;
      opacity: 1;
    }
  }
  .unfollow{
    background-color: #fff;
    border: 1px solid #ddd;
    color: #444;
  }

}

.list-item{
  padding: 10px 16px;
  display: flex;
  align-items: center;
  border-top: 1px solid #eee; 
  &:active{
    background-color: #eee;
  }
  .list-item-text{
    flex:1;
  }
  .font-icon{
    display: flex;
    align-items: center;
  }
}