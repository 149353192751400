.post-head-list{ 
  width: 100%;
  padding: 0px 0px; 
  text-align: left; 
  // background-color: #fff;
  color:#333;
  border-radius: 16px;


  animation: fade-list 2000ms forwards;
  animation-iteration-count: 1;

  .post-head-list-title{
    color:#000;
    font-weight: bold;
    font-size: 16pt;
    padding: 16px 16px;
    border-bottom: 1px solid #eee;
  }
  position: sticky;
  top:-72px;
}
.post-head-list .post-head-item{


 
  // word-break: break-all;
  
  cursor: pointer;
  // margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 16px;
  // background-color: #fff;
  border-bottom: 1px solid #eee;
  line-height: 1.6;

  max-width: 768px;
  // padding-bottom: 24px;
   


  animation: fade-new-item 1000ms forwards;
  animation-iteration-count: 1;

  &.hide{
    display: none;
  }
  &:hover{
    border-radius:  4px;
    
    box-shadow: inset 0px 0px 1000px 10000px #00000011;
  }

  .post-item-title{
    font-size: 10pt;
    font-weight: bold;  
    color: #41425b;
  }
  .post-item-description{ 
    font-size: 10pt; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 68px; 
  } 

  .post-item-date{
    font-size: 9pt;
    color:#595964;
    padding-top: 4px;
  }
}

.post-head-list-content{
  overflow: hidden;
}








 
@keyframes fade-list {
  0% {  opacity: 0;  }  
  50% { opacity: 0;  } 
  100% { opacity: 1;  } 
}
 


 
@keyframes fade-new-item {
  0% {  opacity: 0; transform:scaleY(0);color:#00000000; margin-top: -70px; }  
  50% { opacity: 1;transform:scaleY(1); color:#00000000; } 
  100% { opacity: 1;transform:scaleY(1); } 
}
 
 