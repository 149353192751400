 .comment-item{
  // border-top: 2px solid #f2f2f2;

  width: 100%;
  margin: 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 12px 10px;
  // background-color: #403f4715; 
  // border-radius: 16px;
  line-height: 1.6;
  font-size: 11pt;
  max-width: 768px;
  opacity: 0.9;
  // border: 1px solid #eee;
  overflow: hidden;
  

  .comment-item-sender .name-date{
    display: flex; 
    flex-direction: column;
    line-height: 1.2;
  }

  .comment-item-sender{
    display: flex;
    flex-direction: row;
    align-items: center; 
    font-size: 10pt;
    // margin-top: 8px;
    // padding-bottom: 8px;
    //  border-bottom: 1px solid #00000011;
    width: 100%;
    color:#999;
    opacity: 1;
    // background-color: #fff;
    // padding: 10px;

    img{
      width: 32px;
      height: 32px;
      border:1px solid #ddd;
      border-radius: 50%;
      margin-right: 8px;

    }
    .name{
      // padding-right: 8px;
      color:#666;
    }
    .time{
      //  padding-left: 8px;
      font-size: 7pt;
      color:#aaa;
    }

  }


  .comment-item-title{
    font-weight: bold;
    padding-bottom: 4px; 
  }
  .comment-item-description{
    // padding:   12px;
    padding-left: 40px;
  }
  .comment-item-content{
    // padding: 12px;
    // padding-top: 8px; 
    width: 100%; 
    padding-left: 40px;
    padding-top: 4px;
    line-height: 1;

    img.emoji-comment{
      width: 48px;
      // margin-top: -8px;
    } 
    img.emoji{
      width: 64px;
    }
    img{
      width: 100%;
    }
    video{
      width: 100%;
    }

  }
   



  .youtube-container {
    width: 50%;
    position: relative;
    padding-bottom: 32.25%; /* 16:9 */
    height: 0;
  
    animation: fadevideo 10000ms forwards;
    animation-iteration-count: 1;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;

    .play-button{
      background: #00000077;
      color:#fff;
      font-size: 40pt;
      position: absolute;
      top:50%;
      left:50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      
    }
     
  }
  .youtube-container iframe {
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


}



@media (max-width: 768px)  { 
 .post-item .post-item-sender .label{
     display: none;
  }
}


@media (max-width: 768px)  { 
   .comment-item .comment-item-sender .label{
     display: none;
  }
}


.comment-buttons{
  display: flex;
  padding-left: 32px;
  gap: 8px; 
  opacity: 0.8;
}
.comment-buttons .comment-button{
  line-height: 1;
  padding: 8px;
  cursor: pointer;
  font-size: 8pt;
  display: flex;
  align-items: center;
}
.comment-buttons .comment-button:hover{
  background-color: #eee;
  
}
.comment-buttons .comment-button .font-icon{
  font-size: 12pt;
  font-weight: bold;
  padding-right: 8px;
}