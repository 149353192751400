.tab-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
    margin-bottom: 16px;
    align-self: flex-start;
  .tab-title{
      user-select: none;
      padding: 12px 16px; 
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14pt;
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000011;
      }
      &.selected{
        border-bottom: 2px solid #163ec4;
        margin-bottom: -2px;
      }
  }
}


.tab-content{
  width:100%;
}

.tab-header-wrapper {
  width: 100%;
}

@media (max-width:768px) {
    
  .tab-header-wrapper {
    overflow: auto; 
    white-space: nowrap;   
    display: block;
  }
   
  .tab-header {
    white-space: nowrap; 
    min-width: 0; 
    display: block;
    margin-bottom: 2px;
    .tab-title{
      min-width: 0;
      padding: 12px 16px; 
      white-space: nowrap;  
      border-bottom: 2px solid transparent;
      display: inline-block;
      &:hover{
        box-shadow: none;
      }
      // display: none;
    }
    // .tab-title:last-child{
    //   padding-right: 0px;  
    // }
    // .tab-title:first-child{
    //   padding-left: 0px;  
    // }
    .tab-title.selected{ 
      margin-bottom: 0px;
      // display: block;
    }
  }

}
  