.edit-profile{

  padding: 16px; 

  display: flex;
  flex-direction: column; 
  margin-bottom: 16px;
  align-items: center;


  .avatar{ 
    
    align-self: center;
    justify-self: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: #eee;
    cursor: pointer;
    position: relative;
    opacity: 1;
    transition: 400ms;

    &:hover{
      opacity: 0.5;
      .font-icon{
        display: flex;
      }
    }
    .font-icon{
      position: absolute;
      left:50%;
      transform: translate(-50%, 0);
      bottom:0%; 
      font-size: 30pt;
      opacity: 1;
      background-color: #ffffff99;
      // border-radius: 50%;
      color: #000;
      padding: 4px;
      width: 100%;
      height: 100%;
      text-align: center;
      display: none;
      align-items: center;
      justify-content: center;
    }

  }

  .name{
    padding: 8px 16px;
    padding-top: 16px;
    grid-area: name;
    
    text-align: left; 
    font-weight: bold;
  }  

  .edit-item{

  max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 4px;
      width: 100%;
 
      .edit-item-label{
        padding: 4px;
        font-weight: normal;
      }
  }
}

// .page.profile{
//    padding-top: 10px;  
//    display: flex;
//    flex-direction: row; 
//    justify-content: flex-start;
//   text-align: left;

//   .profile-left{
//     padding: 16px;
//     width:220px
//   }
//   .profile-right{
//     padding: 16px;
//     width:300px;
//     padding-right: 0px;
//   }
//   .profile-center{
//     flex:1;
//     border-right: 1px solid #f2f2f2;
//     border-left: 1px solid #f2f2f2;
//   }
 
// }

// .profile-left{
//   .avatar{
//     width:100%;
//     img{
//       width:100%;
//       height: auto;
//       border-radius: 50%;
//     }
//   }
//   .name{
//     text-align: center;
//     padding: 16px;
//     font-weight: bold;
//   }
//   .stats{
//     .stats-line{
//       padding: 16px 0px;
//       font-size: 10pt;
//       border-bottom: 1px solid #f2f2f2;
//     }
//   }
// }


// .user-list{
//   .user-list-title{
//     font-weight: bold;
//     padding: 16px 0px;
//     border-bottom: 1px solid #eee;
//     margin-bottom: 8px;
    
//   }
//   .user-item{
//     display: grid;

//     grid-gap: 0px 8px;
//     gap: 0px 8px;

//     grid-template-columns:  48px 1fr 80px;  
//     grid-template-rows: auto;
//     grid-template-areas: 
//       "image name btn"
//       "image desc btn" 
//       ; 

//     padding: 8px 0px;
//     margin-bottom: 8px;
//     border-bottom: 1px solid #eee;
//     .avatar{
//         grid-area: image;
//         img{
//             width: 48px;
//             height: 48px;
//             border-radius: 50%;
//         }
//     }
//     .name{
//       grid-area: name;
//       font-weight: bold;
//       align-self: flex-end;
//     }
//     .level{
//       grid-area: desc;
//       font-size: 10pt;
//       align-self: flex-start;
//     }
//     .follow{
//       grid-area: btn;
//       font-size: 10pt;
//       align-self: center;
//       background-color: #0856bc;
//       color: #fff;
//       padding: 6px 12px;
//       text-align: center;
//       font-weight: bold;
//       font-size: 10pt;
//       border-radius: 40px;
//       cursor: pointer;
//       opacity: 0.9;
//       &:hover{
//         box-shadow: inset 0px 0px 1000px 1000px #00000022;
//         opacity: 1;
//       }
//     }
//   }
// }


// @media (max-width: 768px)  {  

// }


 