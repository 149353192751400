
.left-menu{ 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; 
  padding-left: 16px;  
  padding-top: 16px;  
  position: sticky;
  top: 0px;
  height: 100%;
  
 
  .logo{
    // width: 120px;
    // height: auto;
    margin-bottom: 32px;
    cursor: pointer;
  }

  .left-menu-item{
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 100px;
    padding: 12px 16px;
    margin-left: -12px;
    padding-right: 24px;

    font-size: 14pt; 
    font-weight: 500;
    transition: 200ms;
    margin-bottom: 4px;


    .font-icon{
      margin-right: 12px;
      font-size: 140%;
    }
    img{
       width: 24px; 
      margin-right: 12px;
      // opacity: 0.8; 
       

    }

    &:hover{
      box-shadow: inset 0px 0px 1000px 10000px #00000011;
      color: #000;
    }

    &.selected{
      background-color: #00000011;
    }

    &.disabled{
      pointer-events: none;
      opacity: 0.2;
    }
 
  }

}

 
 

.account-menu{
  // position: sticky;
  // top:calc( 100% - 64px);
  padding: 4px ;
  // margin-left: 8px;
  display: grid;
  grid-gap: 0px 8px;
  gap:0px 8px;
  z-index: 2;
  background-color: #fff;
   border-radius: 1000px;
  grid-template-columns: 48px 1fr 20px;
  grid-template-rows: auto;
  grid-template-areas: 
  "avatar name icon"
  "avatar desc icon";
  cursor: pointer;
  transition: 200ms;

  &:hover{
    box-shadow: inset 0px 0px 1000px 1000px #00000011;
  }

  .avatar{
    grid-area: avatar;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: #eee;
  }
  .name{
    grid-area: name;
    align-self: flex-end;
    font-size: 10pt;
  }
  .desc{
    grid-area: desc;
    font-size: 9pt;
    align-self: flex-start;
  }
  .more{
    grid-area: icon;
    font-weight: bold;
    align-self: center;
    font-size: 15pt;
  }

}


.account-popover{


  // position: sticky;
  // top:calc( 100% - 8px); 

  position: fixed;
  bottom: 0px;
  width: 200px;
  
  // width: calc(100% );
  // transform: translate(0px, -100%); 
  padding: 8px 8px;
  
   
  // padding: 8px ; 
  // margin-left: 8px;
   
  // border-radius: 100px;
  border-radius: 20px 20px 24px 24px;
  // overflow: hidden; 
    box-shadow: 0px 0px 10px 0px #00000000; 
    background-color: #fff;
    transition: 350ms;

  .account-popover-item{
    // display: none;

    transition: 200ms;
    opacity: 0; 
    transform: translateY(300%);  
    margin-top: -50px;
    z-index: -1;

    a{
      text-decoration: none;
      color: #000;
    }
  }
  &:hover{  
    z-index: 10;
    padding-top: 18px;
    // width: calc(100% + 0px);
    // transform: translate(0px, -100%);
    // padding: 8px 8px;
    box-shadow: 0px 0px 10px 5px #0000000d; 
    .account-popover-item{
      z-index: 1;
      margin-top: 0px;
      display:block;
      opacity: 1;
      transform: translateY(-8px); 
    }

  }
 

  .account-popover-item{
 
    border-radius: 1000px;
    padding: 12px 16px;
    
    cursor: pointer;
     
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000011;
    }

    // &:first-child  {
    //   margin-top: 8px;
    // }
    &:first-child + .account-popover-item{
      // margin-bottom: 8px;
    }
    
  }

}



// .logomask{
//   position:absolute;
//   width:142px;
//   z-index:10;
//   top:-1px;
//   left:-1px;
//   height: 36px;
//   filter: contrast(10) drop-shadow(1px 1px 2px  #00000088);
// }
// .logomask.resdf{
//   opacity: 0.1;
//   display: none;
// }

// .logobg{
//   position:absolute;
//   width:139px;
//   height:34px;
//   overflow: hidden;

  

//   img:not(.logomask){
//     width: 100%;
    
//     animation: animate-logo 10000ms linear;
//     animation-iteration-count: 100;
     
//   }
  
// }


// @keyframes animate-logo {
//   0% {  transform: translateY(-90%);}  
//   100% {  transform: translateY(-0%); } 
//   // 100% {  transform: translateY(-90%); } 
// }