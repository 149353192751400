 
    
    .start-post{
        padding: 16px 8px;
        
        padding-bottom: 8px;
        // border-radius: 30px;
        background-color: #fff;
        font-size: 10pt;
        // margin-bottom: 24px;

        border-bottom: 2px solid #eee;
        margin-bottom: 8px; 
        // padding-bottom: 48px; 

        animation: fade-start-posts 800ms forwards;
        animation-iteration-count: 1; 
      
        .post-title{
          height: 48px;
          font-size: 14pt;
          padding: 8px 8px;
          border-radius: 32px;

          background-color: transparent;
          border: 0px none;
          border-bottom: 1px solid #eee;
          border-radius: 0px;
          // margin-bottom: 8px; 
        }

        .post-title.large{
          height: 110px; 
          border-radius: 16px;
        }

        .post-desc{
          height: 80px;
          font-size: 12pt;
          padding: 10px 8px;
          margin-bottom: 8px;
          border-radius: 16px;
          margin-top: 8px;
          background-color: transparent;
          border: 0px none;
        }
        textarea{
          border-radius: 16px;
          border:1px solid #e2e2e2;
        }

        .start-post-bottom{
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .post-flags{
          padding: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex:1;
          .post-flag{
            font-size: 10pt;
            border-radius: 10px;
            padding: 4px 12px;
            border:1px solid #ddd;
            margin-right: 8px;
            cursor: pointer;
            opacity: 0.8;
            color:#777;
            &:hover{
              box-shadow: inset 0px 0px 1000px 10000px #00000011;
            }
            &.selected{
              background-color: #ffd035b3;
              border-color: rgb(230, 173, 29);
              opacity: 1;
              color:#000;
            }
          }
        }
        .post-types{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            &.send-reel{
              flex:1;
              justify-content: flex-start;
            }
            .post-type{
              display: flex;
              flex-direction: row;
              align-items: center; 
              padding: 4px 0px;
              margin: 0px 16px;
              cursor: pointer;
              filter: grayscale(0.4);
              opacity: 0.8;
              img{
                width: 24px;
                margin-right: 4px;
              }

              &:hover{ 
                filter: grayscale(0);
                opacity: 1;
              }

              &.choose-reel{
                justify-self: flex-start;
                font-weight: bold;
                margin: 0px 4px;
                .font-icon{
                  font-size: 38px;
                  margin-right: 8px;
                 
                }
                
              }
            }


        }






        .start-post-send{
          // border-top: 1px solid #eee;
          // padding-top: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          // margin-top: 16px;
          .start-post-cancel-button{  
            font-size: 10pt;
            padding: 8px 16px;
            background-color: #fafafa;
            border:1px solid #eee;
            border-radius: 50px;
            cursor: pointer;

            display: flex;
            flex-direction: row;
            align-items: center; 
            img{
              width: 24px;
              margin-right: 4px;
            }


            &:hover{
              box-shadow: inset 0px 0px 1000px 10000px #00000011;
            }
            margin-right: 16px;
          }
          .start-post-send-button{
            font-size: 10pt;
            font-weight: bold; 
            padding: 8px 16px;
           color:#000;

            border:1px solid #ddd;
            border-radius: 50px;
            cursor: pointer;

            display: flex;
            flex-direction: row;
            align-items: center; 
            filter: grayscale(1);
            opacity: 0.3;
            padding-right: 24px;
            img{
              width: 16px;
              margin-right: 8px;
            }

            .font-icon{
              margin-right: 8px;
            }


            &:hover{
              box-shadow: inset 0px 0px 1000px 10000px #00000011;
            }
            &.active{
              filter: none;
              opacity: 0.9;
              background-color: #ffc600;
              border:1px solid transparent;
            }
          }
        }



        &.disabled{
          opacity: 0.3 !important;
          pointer-events: none;
          user-select: none;
          filter: grayscale(1);
        }

    }



    .start-post.comment, .start-post.comment.closed, .start-post.comment.opened{ 
      .post-title{ 
        border:1px solid #eee;
        padding: 16px; 
      }
      
    }
   

  .start-post.closed{
    padding-bottom: 8px;
    .post-title{
      border-bottom: 0px none; 
    }
    .post-desc, .start-post-bottom, .post-types, .post-flags, .start-post-send, .file-upload{ display: none; }
  }
 


  .post-ready{
    display: flex;
    flex-direction: row;
    // align-items: center;
    padding-bottom: 8px;

    .post-ready-item{
      display: flex;
      flex-direction: row;
       align-items: center;
       justify-content: center;
       max-width: 90px;
      padding: 6px;
      cursor: pointer;
      background-color: #eee;
      margin: 2px;
      border-radius: 8px;
      flex: 1;
    }
    img{
      width: 24px;
    }
  }

  .media-uploader{
    display:none;
  }

  .file-preview{
    width: 300px;
    height: 300px;
    background-position:center 20%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #000;
    position: relative;

  .file-preview-remove{
    position: absolute;
    top:10px;
    right: 10px;
    width: 22px;
    height: 22px;
    background-color: #00000022;
    color:#fff;
    z-index: 10;
    border-radius: 50%;
    padding: 0px;
    margin: 0px;
    .font-icon{
      margin: 0px;
      font-size: 22px;
    }
  }

  }
  .media-uploader-thumbnail{
    margin:10px 0px;
    border-radius: 16px;
    overflow: hidden;

    :hover{
      opacity: 0.7;
    }
  }

  .video-react{
    background-color: transparent;
  }



@keyframes fade-start-posts {
  0% {  opacity: 0;}  
  50% {  opacity: 0;}  
  100% { opacity: 1;} 
}
