 
      .message-user-item{
        position: relative;
        display: grid;

        grid-gap: 0px 8px;
        gap: 0px 8px;

        grid-template-columns:  48px 1fr;  
        grid-template-rows: auto;
        grid-template-areas: 
          "image name "
          "image desc " 
          ; 

        padding: 8px ;
        // margin-bottom: 16px;
        border-bottom: 1px solid #eee;

        &:hover{
          background-color: #fafafa;
        }

        &.selected{
          background-color: #f2f2f2;
        }

        .avatar{
          cursor: pointer;
            grid-area: image;
            img{
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }
        }
        .name{
          cursor: pointer;
          grid-area: name;
          font-weight: bold;
          align-self: flex-end;
          font-size: 10pt;
        }
        .level{
          cursor: pointer;
          grid-area: desc;
          font-size: 9pt;
          height: 25px;
          align-self: flex-start;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .notify{
          position: absolute;
          font-size:8pt;
          font-weight: bold;
          color:#fff;
          background-color: #ff0000;
          border-radius: 50%;
          width:20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          left:40px;
          top:10px;
        }
        .follow, .unfollow{
          grid-area: btn;
          font-size: 10pt;
          align-self: center;
          background-color: #0856bc;
          color: #fff;
          padding: 6px 12px;
          text-align: center;
          font-weight: bold;
          font-size: 10pt;
          border-radius: 40px;
          cursor: pointer;
          opacity: 0.9;
          &:hover{
            box-shadow: inset 0px 0px 1000px 1000px #00000022;
            opacity: 1;
          }
        }
        .unfollow{
          background-color: #fff;
          border: 1px solid #ddd;
          color: #444;
        }
      } 