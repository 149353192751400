.message-item{

  margin-bottom: 12px;
  display: grid;
    
  grid-gap: 0px 8px;
  gap: 0px 8px;

  grid-template-columns:  52px auto 1fr;  
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "avatar name time"
    "avatar content content" 
    "aa content content" ; 
 
  max-width: 76%;

  .avatar{
    grid-area: avatar;
    img{
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
  .name{
    grid-area: name;
    font-size: 8pt;
    color: #555;
    padding-left: 6px;
    margin-bottom: 2px;
  }
  .time{
    grid-area: time;
    font-size: 8pt;
    opacity: 0.3;
  }
  .content{
    display: grid;
    grid-area: content;
    .content-inner{
      justify-self: flex-start;
      padding: 8px 16px;
      min-width: 80px;
      min-height: 32px;
      font-size: 10pt;
      background-color:  #31334a;
      color: #fff;
      border-radius: 8px;
      width: auto;
      display: inline-block;
      position: relative;
      &:before{
        content :' ';
        width: 10px;
        height: 10px;
        background-color:  #31334a;
        position: absolute;
        left:-4px;
        top:8px;
        border-radius: 2px;
        transform: rotate(45deg);

      }
    }
  }
}

.message-item.me{
 
  grid-template-columns:  1fr auto;  
  grid-template-rows: auto;
  grid-template-areas: 
    "time name"
    "content content" 
    "content content" ; 
 
  max-width: 76%;
  align-self: flex-end;
  margin-right: 16px;

  .name{
    text-align: right;
  }
  .avatar{
    display: none;
  }
  .time{
    grid-area: time; 
    text-align: right;
  }

  .content-inner{ 
    justify-self: flex-end;
    background-color: #ffe311;
    color:#000;
    &:before{
      background-color: #ffe311;
      left:auto;
      right:-4px;
      top:8px;
     
    }
  }
}