.post-list{
  min-height: 600px;

  animation: fade-posts 800ms forwards;
  animation-iteration-count: 1; 
}


@keyframes fade-posts {
  0% {  opacity: 0;}  
  50% {  opacity: 0;}  
  100% { opacity: 1;} 
}
